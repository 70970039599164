<template>
  <a
    v-if="props.item.url"
    :href="props.item.url"
    target="_blank"
    class="font-semibold text-on-surface p-2 cursor-pointer text-ellipsis overflow-hidden"
  >
    {{ props.item.name }}
  </a>

  <template v-else>
    <button
      class="font-semibold text-on-surface flex items-center p-2 cursor-pointer text-left"
      @click="isExpanded = !isExpanded"
    >
      <span class="flex-1 text-ellipsis overflow-hidden">
        {{ props.item.name }}
      </span>
      <Icon
        v-if="hasSubItems"
        :icon="ArrowDownSvg"
        class="flex-shrink-0 ml-2"
      />
    </button>
    <div v-if="isExpanded">
      <a
        v-for="subItem in props.item.submenuItems"
        :key="subItem.id"
        target="_blank"
        :href="subItem.url"
        class="text-on-surface block ml-4 px-3 py-2 cursor-pointer text-ellipsis overflow-hidden"
      >
        {{ subItem.name }}
      </a>
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, computed } from '#imports';
import ArrowDownSvg from '@/assets/icons/arrow-down.svg?component';
import { MenuItemDto } from '~/api/organizations';
import { Icon } from '~/components/icon';

type Props = {
  item: MenuItemDto;
};

const props = defineProps<Props>();

const isExpanded = ref<boolean>(false);
const hasSubItems = computed(() => props.item.submenuItems.length > 0);
</script>
